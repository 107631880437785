<template>
  <div>
    <div class="point_default_wrap">
      <table class="my_point_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
             style="table-layout: auto; width: 1200px; table-layout: fixed;">
        <colgroup>
          <col width="300px" />
          <col width="300px" />
          <col width="300px" />
          <col width="300px" />
        </colgroup>
        <tr>
          <td rowspan="4" class="t_subject">{{$t('usa_withdraw_mingle')}}</td>
          <td rowspan="4"><span class="point_text">12,345 Mg</span></td>
          <td class="t_subject">{{ $t('real_used_trade') }}</td>
          <td class="t_content">35,48222222222222222221 Mg</td>
        </tr>
        <tr>
          <td class="t_subject">{{ $t('auction_bid_mingle') }}</td>
          <td class="t_content">30,000 Mg</td>
        </tr>
        <tr>
          <td class="t_subject">{{ $t('competition_open_mingle') }}</td>
          <td class="t_content">12,000 Mg</td>
        </tr>
        <tr>
          <td class="t_subject">{{ $t('withdraw_mingle') }}</td>
          <td class="t_content">12,000 Mg</td>
        </tr>
        <tr>
          <td class="t_subject">{{ $t('all_mingle') }}</td>
          <td class="t_content" colspan="3"><span class="total_mg">101,826 Mg</span></td>
        </tr>
      </table>
      <div class="confirm_btn_wrap">
        <button class="btn charge" @click="movePage('/mypage/point/charge')">{{$t('charge_mingle')}}</button>
        <button class="btn save" @click="movePage('/mypage/point/withdraw')">{{$t('request_withdraw')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PointListDefaultLayout",
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(url) {
      this.$router.push(url);
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>